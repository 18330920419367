import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

const App: React.FC = () => {
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [courier, setCourier] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [showMap, setShowMap] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setPosition([pos.coords.latitude, pos.coords.longitude]);
      },
      (err) => {
        console.error(err);
      },
      { enableHighAccuracy: true }
    );
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ courier, comments, position });
    // Send report to backend or store locally
  };

  const customIcon: L.Icon = new L.Icon({
    iconUrl: "location_marker.png",
    iconSize: [60, 60],
    iconAnchor: [30, 60],
  });

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 text-white">
      <h1 className="text-5xl font-bold mb-8 text-center">I Was Home. Where's my package?</h1>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 mb-8">
        <button 
          onClick={() => setShowForm(true)} 
          className="bg-white text-blue-500 font-bold text-xl py-4 px-8 rounded-lg shadow-lg hover:bg-gray-100 transition duration-300"
        >
          Report a Missed Delivery
        </button>
        <button 
          onClick={() => setShowMap(true)} 
          className="bg-white text-blue-500 font-bold text-xl py-4 px-8 rounded-lg shadow-lg hover:bg-gray-100 transition duration-300"
        >
          View Map
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md text-black">
          <label className="block mb-2">
            Courier Name:
            <input 
              value={courier} 
              onChange={(e) => setCourier(e.target.value)} 
              required 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <label className="block mb-2">
            Comments:
            <textarea 
              value={comments} 
              onChange={(e) => setComments(e.target.value)} 
              required 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <button type="submit" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-md hover:bg-blue-600">
            Submit Report
          </button>
        </form>
      )}

      {showMap && position && (
        <MapContainer center={position} zoom={13} style={{ height: "50vh", width: "100%" }} className="mt-6">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            {/* <Popup>Your location.</Popup> */}
          </Marker>
        </MapContainer>
      )}

      {!position && <p className="mt-6">Loading your location...</p>}
    </div>
  );
};

export default App;
